<template>
    <div class="container mt-5 snapshot-not-found" style="display: none;">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="text-center">
                    <h3 class="mt-3">
                        <i class="bi bi-x-octagon"></i> Snapshot Not Found 
                    </h3>
                    <p class="text-muted">The requested snapshot does not exist, or you do not have access to it.</p>
                </div>
            </div>
        </div>
    </div>
    
    <div id="cChart_SnapshotMonteCarlo" style="width:100%; height:100%;"></div>
</template>

<script setup type="ts">
    import { getOrCreateDataObject } from 'o365-dataobject';

    const props = defineProps({
        load: function(options) {
            var dsMontecarlo = this.dsMontecarlo,
                dsSimulations = this.dsSimulations,
                renderChart = this.renderChart,
                notFound = document.querySelector("div.snapshot-not-found");

            //reset
            notFound.style.display = "none";
            cChart_SnapshotMonteCarlo.innerHTML = "";

            if (dsSimulations.hasDataLoaded == undefined) {
                dsSimulations.hasDataLoaded = true;
                dsSimulations.on("DataLoaded", function() {
                    renderChart(options, dsMontecarlo.current, dsSimulations.data);
                });
            }

            if (options.Snapshot_ID) {
                dsMontecarlo.recordSource.whereClause = "ID = " + options.Snapshot_ID;
            }
            else if (options.OrgUnit_ID) {
                if (!options.Type || options.Type != "Schedule") { options.Type = "Cost"; }
                dsMontecarlo.recordSource.whereClause = "OrgUnit_ID = " + options.OrgUnit_ID + " AND Type = '" + options.Type + "'";
            }

            dsMontecarlo.load().then(function() {
                if (dsMontecarlo.data.length == 0) {
                    notFound.style.display = "block";
                    return;
                }

                dsSimulations.recordSource.whereClause = "Snapshot_ID = " + dsMontecarlo.current.ID;
                dsSimulations.load();
            });
        },
        renderChart: function(options, snapshot, simulationData) {
            if (snapshot.Type === 'Cost') {
                //Load in Cost Chart
                var vCostSeries = [],
                    vCostCategories = [],
                    vCumulativePercent = 0,
                    vPercentFrom = 0,
                    vPercentTo = null,
                    vValueTo = null,
                    vValueFrom = null,
                    vIndex = 0,
                    vPreviousCumulativePercent = 0,
                    vPreviousValueTo = 0,
                    vCurrentPercent = 0,
                    FromBool = true,
                    ToBool = true,        
                    vToFromText = null; 

                vCostSeries.push({name: 'Value', data: []});
                vCostSeries.push({name: 'Percentages', data: []});
                
                simulationData.forEach(pRow => {
                    //Calculate cumulative percent
                    vCumulativePercent += pRow.Percent;

                    //Populate Categories and Series for chart
                    vCurrentPercent = Number((vCumulativePercent * 100).toFixed(2));
                    vCostCategories.push(Number((pRow.ValueTo / 1000).toFixed(0)));
                    vCostSeries[0].data.push(Number((pRow.Percent * 100).toFixed(2)));
                    vCostSeries[1].data.push(Number((vCumulativePercent * 100).toFixed(2)));

                    //Calculate PlotBands from 5% to 95% for chart
                    //Formula for PercentFrom/PercentTo: Index - 1 + (PercentToCheck - PreviousCumulativePercent) / (CumulativePercent - PreviousCumulativePercent)
                    //PercentToCheck is 5 or 95 in this case
                    //
                    //Formula for ValueFrom/ValueTo: (Value - PreviousValue) * (PercentToCheck - PreviousCumulativePercent) / (CumulativePercent - PreviousCumulativePercent) + PreviousValue
                    //Value is ValueTo or ValueFrom
                    //PercentToCheck is 5 or 95 in this case
                    if (vCurrentPercent <= 5) {
                        vPercentFrom = vIndex;
                        vValueFrom = pRow.ValueTo;
                    } else if (vCurrentPercent > 5 && FromBool) {
                        FromBool = false;
                        vPercentFrom = vIndex - 1 + (5 - vPreviousCumulativePercent) / (Number((vCumulativePercent * 100).toFixed(2)) - vPreviousCumulativePercent);
                        if (vPercentFrom < 0) {
                            vPercentFrom = vPercentFrom * 0.5;
                        };
                        vValueFrom = ((pRow.ValueTo - vPreviousValueTo) * (5 - vPreviousCumulativePercent) / (Number((vCumulativePercent * 100).toFixed(2)) - vPreviousCumulativePercent)) + vPreviousValueTo;
                    }
                    if (vCurrentPercent <= 95) {
                        vPercentTo = vIndex;
                        vValueTo = pRow.ValueTo;
                    } else if (vCurrentPercent > 95 && ToBool) {
                        ToBool = false;
                        vPercentTo = vIndex - 1 + (95 - vPreviousCumulativePercent) / (Number((vCumulativePercent * 100).toFixed(2)) - vPreviousCumulativePercent);
                        vValueTo = ((pRow.ValueTo - vPreviousValueTo) * (95 - vPreviousCumulativePercent) / (Number((vCumulativePercent * 100).toFixed(2)) - vPreviousCumulativePercent)) + vPreviousValueTo;
                    }
                    vPreviousCumulativePercent = Number((vCumulativePercent * 100).toFixed(2));
                    vPreviousValueTo = pRow.ValueTo
                    vIndex += 1;
                });

                //Populate Plotline To and From
                vToFromText = `5% to 95% (${Number((vValueFrom / 1000).toFixed(2))} to ${Number((vValueTo / 1000).toFixed(2))})`;
                // console.log(vPercentFrom + ' and ' + vPercentTo);
                // console.log(vValueFrom + ' and ' + vValueTo);

                Highcharts.chart("cChart_SnapshotMonteCarlo", {
                    chart: {
                        zooming: {
                            type: 'xy'
                        }
                    },
                    title: {
                        text: options.Snapshot_ID ? "Total Capital Result" : "Latest Snapshot: " + snapshot.Title,
                        align: 'center'
                    },
                    xAxis: [{
                        title: {
                            text: 'Values in Thousands',
                            style: {
                                color: '#000000'
                            },
                            margin: 30
                        },
                        categories: vCostCategories,
                        crosshair: true,
                        labels: {
                            rotation: 270,
                            x: 5
                        },
                        tickWidth: 1,
                        tickmarkPlacement: 'on',
                        gridLineColor: 'lightgray',
                        gridLineWidth: 1,
                        gridZIndex: 1,
                        tickInverval: 1,
                        plotBands: [{
                            zIndex: 4,
                            color: 'rgba(198,218,225, 0.4)',
                            from: vPercentFrom,
                            to: vPercentTo,
                            label: {
                                text: vToFromText
                            }
                        }]
                    }],
                    yAxis: [{ // Secondary yAxis
                        min: 0,
                        max: 100,
                        // tickInterval: 10,
                        // tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                        labels: {
                            format: '{value}%',
                            style: {
                                color: '#000000'
                            }
                        },
                        title: {
                            text: 'Percentages (Cumulative)',
                            style: {
                                color: '#000000'
                            }
                        },
                        opposite: true
                    }, { // Primary yAxis
                        labels: {
                            format: '{value}%',
                            style: {
                                color: '#000000'
                            }
                        },
                        title: {
                            text: 'Percentages (Periodic)',
                            style: {
                                color: '#000000'
                            }
                        },
                    }],
                    tooltip: {
                        shared: true
                    },
                    legend: {
                        align: 'left',
                        verticalAlign: 'top',
                        backgroundColor:
                            Highcharts.defaultOptions.legend.backgroundColor || // theme
                            'rgba(255,255,255,0.25)'
                    },
                    series: [{
                        name: 'Values',
                        type: 'column',
                        yAxis: 1,
                        data: vCostSeries[0].data,
                        tooltip: {
                            valueSuffix: '%'
                        },
                        color: '#cc0000',
                        groupPadding: 0,
                        pointPadding: 0,
                        borderWidth: 0.4,
                        borderColor: 'black',
                        showInLegend:false
                    }, {
                        name: 'Percentages',
                        type: 'spline',
                        data: vCostSeries[1].data,
                        tooltip: {
                            valueSuffix: '%'
                        },
                        color: '#990000',
                        marker: {
                            enabled: false
                        },
                        showInLegend:false
                    }],
                    credits: {
                        enabled: false
                    }
                });
            } else {
                //Load in Schedule Chart
                var vScheduleSeries = [],
                    vScheduleCategories = [],
                    vPreviousValueTo = 0,
                    vCumulativePercent = 0;

                vScheduleSeries.push({name: 'Probability', data: []});
                vScheduleSeries.push({name: 'Cumulative', data: []});

                simulationData.forEach(pRow => {
                    //Calculate cumulative percent
                    vCumulativePercent += pRow.Percent;
                    vScheduleCategories.push(String(Number((vPreviousValueTo).toFixed(0))) + ' to ' + String(Number((pRow.ValueTo).toFixed(0))));
                    vScheduleSeries[0].data.push(Number((pRow.Percent * 100).toFixed(2)));
                    vScheduleSeries[1].data.push(Number((vCumulativePercent * 100).toFixed(2)));

                    vPreviousValueTo = pRow.ValueTo;
                });

                Highcharts.chart("cChart_SnapshotMonteCarlo", {
                    chart: {
                        zooming: {
                            type: 'xy'
                        }
                    },
                    title: {
                        text: options.Snapshot_ID ? "Simulation" : "Latest Snapshot: " + snapshot.Title
                    },
                    xAxis: {               
                        labels: {
                            step: 2, 
                            rotation: -45,
                            y: 50,
                            x: -22
                        },
                        categories: vScheduleCategories,
                    },
                    yAxis: [{
                        title: {
                            text: ""
                        },
                        min: 0,
                        labels: {
                            format: '{value}%'
                        }
                    }, {
                        title: {
                            text: ""
                        },            
                        min: 0,
                        opposite: true,
                        labels: {
                            format: '{value}%'
                        }            
                    }],
                    plotOptions: {
                        series: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                    tooltip: {
                        formatter: function() {
                            let vTT = "<bold style='color:" + this.series.color + ";'>" + this.series.name + "</bold>";
                            vTT += "<br>" + (Math.floor(this.y * 10) / 10) + "% fra " + this.x;
                            return vTT
                        }
                    },
                    series: [{
                        name: 'Values',
                        type: 'column',
                        data: vScheduleSeries[0].data,
                        tooltip: {
                            valueSuffix: '%'
                        },
                        color: '#0099FF',
                        // groupPadding: 0,
                        // pointPadding: 0,
                        borderWidth: 0.4,
                        borderColor: 'black',
                        showInLegend:false
                    }, {
                        name: 'Percentages',
                        type: 'spline',
                        data: vScheduleSeries[1].data,
                        yAxis: 1,
                        tooltip: {
                            valueSuffix: '%'
                        },
                        color: '#FF6633',
                        marker: {
                            enabled: false
                        },
                        showInLegend:false
                    }],
                    credits: {
                        enabled: false
                    }
                });
            }
        },
        dsMontecarlo: getOrCreateDataObject({
            "id": "dsMontecarlo_" + crypto.randomUUID(),
            "viewName": "atbv_MSFT_Snapshots_Montecarlo",
            "distinctRows": false,
            "maxRecords": 1,
            "selectFirstRowOnLoad": true,
            "fields": [
                {"name": "ID",},
                {"name": "Created", "sortOrder": 1,"sortDirection": "desc"},
                {"name": "Type",},
                {"name": "Title",}
            ]
        }),
        dsSimulations: getOrCreateDataObject({
            "id": "dsSimulations_" + crypto.randomUUID(),
            "viewName": "atbv_MSFT_Snapshots_MontecarloSimulations",
            "distinctRows": false,
            "maxRecords": -1,
            "selectFirstRowOnLoad": true,
            "fields": [
                {"name": "Snapshot_ID"},
                {"name": "ValueFrom"},
                {"name": "ValueTo"},
                {"name": "Percent"}
            ]
        })
    });
</script>;